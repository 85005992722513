<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Nordigen</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-card class="mt-2">
      <v-container>
        <h2>Nordigen valtuutus</h2>
        <p class="font-weight-bold mb-1">
          Valtuutus vanhenee 90 päivässä. Tämän jälkeen on tehtävä uusi valtuutus. Vuokranet pyytää
          sinulta uudelleen valtuutusta kun sitä tarvitaan.
        </p>
        <p>Valtuutusta voidaan käyttää seuraavissa Vuokranet toimenpiteissä:</p>
        <ul>
          <li>Valtuutuksessa valittujen tilien transaktiotietojen haku (tietoja ei tallenneta)</li>
          <li>
            Lähtevien maksujen seuranta (kohdistuneet tai kohdistumattomat transaktiotiedot
            tallennetaan)
          </li>
          <!-- <li>Vastaanotettujen vuokravakuuksien seuranta</li> -->
        </ul>

        <div v-if="!nordigenAuthorized || reAuthorize">
          <v-row dense>
            <v-col cols="12" md="5" lg="4">
              <p class="mt-1">
                Muista valtuuttaa kaikki tilisi, joita tarvitset toimenpiteiden tekemiseksi.
              </p>
            </v-col>
          </v-row>

          <!-- ACTION BTN -->
          <v-row v-show="institutions.length == 0" dense>
            <v-col cols="12">
              <p class="mt-1">
                Valtuuta Vuokranet tilisi käyttämään Nordigenia tilitietojesi tarkasteluun
              </p>
              <v-btn color="primary mt-1" @click="loadInstitutions">{{
                reAuthorize ? "Valtuuta uudelleen" : "Valuuta"
              }}</v-btn>
              <v-btn
                v-if="reAuthorize"
                class="ml-2"
                color="warning mt-1"
                @click="reAuthorize = false"
                >Peruuta uudelleen valtuutus</v-btn
              >
            </v-col>
          </v-row>

          <!-- INSTITUTIONS BANKS -->
          <v-row class="mt-1" dense>
            <v-col v-if="loadingInstitutions" cols="12">
              <!-- Loader -->
              <full-page-loader
                text="Haetaan pankkeja..."
                class="full-page-loader"
              ></full-page-loader>
            </v-col>

            <v-col v-if="!loadingInstitutions && institutions.length > 0" cols="12">
              <v-row dense>
                <v-col
                  v-for="(institution, idx) in institutions"
                  :key="'a' + idx"
                  cols="6"
                  sm="4"
                  md="3"
                  lg="2"
                >
                  <v-card
                    class="h-full pb-2 institution-wrapper"
                    @click="getConsentLink(institution.id)"
                  >
                    <v-card-text style="padding: 0">
                      <h3 class="text-center pt-1 pb-1">{{ institution.name }}</h3>
                      <v-img :src="institution.logo" contain max-height="170px" class="pa-1">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <!-- MESSAGE -->
        <v-row dense class="mt-1">
          <v-col cols="12">
            <h3 :class="nordigenAuthorized ? 'success--text' : 'warning--text'">
              {{ nordigenAuthorized ? "Valtuutus tehty" : "Valtuutusta ei ole vielä tehty" }}
            </h3>
            <v-btn
              v-if="!reAuthorize && nordigenAuthorized"
              small
              color="primary mt-1"
              @click="reAuthorize = true"
              >Valtuuta uudelleen</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
import { mapActions, mapMutations, mapState } from "vuex";
import FullPageLoader from "../../../components/FullPageLoader.vue";

export default {
  title: "Nordigen valtuutus",

  components: { FullPageLoader },

  mixins: [mixins],

  data() {
    return {
      reAuthorize: false,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("nordigen", ["institutions", "loadingInstitutions", "message"]),

    nordigenAuthorized() {
      return this.currentUser.currentAccount?.settings?.openBanking?.nordigen?.authorized;
    },
  },

  async mounted() {
    if (this.$route.query.error) {
      if (this.$route.query.error == "UserCancelledSession") {
        this.showPopup("Käyttäjä keskeytti valtuutuksen", "error");
      }
      return;
    } else if (this.$route.query.ref) {
      try {
        const requisitionId = this.$route.query.ref;
        await this.saveAccounts(requisitionId);
        await this.getCurrentUser();
      } catch (err) {
        this.showPopup(err, "error");
      }
    }
  },

  methods: {
    ...mapActions("nordigen", ["getInstitutions", "getConsentLink", "saveAccounts"]),
    ...mapMutations("nordigen", ["setLoading"]),
    ...mapActions("account", ["getCurrentUser"]),

    loadInstitutions() {
      this.setLoading({ field: "loadingInstitutions", val: true });
      this.getInstitutions();
    },
  },
};
</script>

<style scoped>
.institution-wrapper:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
</style>
